body {
  margin: 0;
  font-family: "Helvetica", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
  font-size: 100%;
}

.pg-viewer-wrapper {
  overflow-y: hidden !important;
}

.pg-viewer-wrapper .document-container {
  width: 100% !important;
  box-sizing: border-box !important;
  padding: 0 !important;
}